.main-panel {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    padding: 10px 20px;
    z-index: 99999;
    border: none;
    background: none;
    margin: 0;

    &__divider {
        display: inline-block;
        position: static;
        background: $ui-base;
        opacity: 0.3;
        width: 1px;
        height: 20px;
        border-radius: 20px;
        margin: 0 30px 0 0;
        position: relative;
        top: 5px;
    }

    &__title {
        font-size: 1em;
        text-align: center;
        margin: 0;
        margin-right: 20px;
        position: relative;
        display: inline-block;
        font-weight: 400;
        font-family: "Alegreya Sans SC", sans-serif;
    }

    &__footer {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        font-size: 0.9em;
    }

    &__buttons {
        display: inline-block;
    }

    &__button {
        display: inline-block;
        font-size: 0.8em;
        margin: 0 30px 0 0;
        position: relative;
        top: 0px;

        &--condensed {
            margin: 0 15px 0 0;
        }

        svg {
            height: 1.25em;
            position: relative;
            top: -2px;
            margin-right: 0.5em;
            fill: $ui-base;
        }
    }

    &__game-menu {
        width: 335px;
        position: fixed;
        top: 0;
        left: 0;
        margin: 10px 20px;
    }
}
