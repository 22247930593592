@keyframes view-close {
    0% {
        opacity: 1;
        // transform: scale(1);
    }
    100% {
        opacity: 0;
        // transform: scale(0.95);
    }
}

.view {
    position: fixed;
    top: 0;
    left: 0;
    background: $view-bg;
    width: 100%;
    height: 100%;
    z-index: 9999;
    color: $ui-base;
    font-weight: 300;
    overflow: auto;
    padding-top: 50px;
    font-family: "Alegreya Sans SC", sans-serif;
    transform-origin: center;

    /* width */
    &::-webkit-scrollbar {
        width: 10px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: $view-bg;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: rgba(255,255,255,0.25);
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: $ui-base;
    }

    &--closing {
        overflow:hidden;
        animation: view-close 0.15s 1 forwards ease-out;
    }

    &--opening {
        overflow:hidden;
        animation: view-close 0.15s 1 reverse forwards ease-in;
    }

    &__title {
        font-size: 2em;
        padding: 10px 20px;
        font-family: "Alegreya Sans SC", sans-serif;
        position: fixed;
        top: 0;
        left: 0;
        svg {
            height: 0.8em;
            position: relative;
            top: -2px;
            margin-right: 0.1em;
            fill: $ui-base;
        }
    }

    &__close {
        position: fixed;
        top: 10px;
        right: 25px;
        font-size: 60px;
        height: 27px;
        line-height: 25px;
    }
}
