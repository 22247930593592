html,
body,
.app {
    overscroll-behavior: none;
}

.app {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: $app-surface;
    color: #fff;
    font-family: "Alegreya Sans SC", sans-serif;
    font-weight: 300;
    z-index: 999;
}


// .header {
//     display:none;
//     position: fixed;
//     top: 0;
//     left: 0;
//     width: 100%;
//     background: rgba(0, 0, 0, 0.5) !important;
//     box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
//     z-index: 9999;
//     height: 50px;

//     &__title {
//         font-size: 1.5em;
//         padding: 0 10px;
//         float:left;
//         line-height: 50px;
//     }

//     &__buttons {
//         float:left;
//     }

//     &__button {
//         font-size: 1.1rem;
//         line-height: 50px;
//         padding: 0 15px;

//         display: inline-block;
//     }

//     &__date {
//         font-size: 2rem;
//     }
// }

// .footer {
//     position: fixed;
//     bottom: 0;
//     left: 0;
//     width: 100%;
//     background: rgba(0, 0, 0, 1) !important;
//     box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
//     color: #fff;
//     padding: 5px 10px;
//     z-index: 9999;

//     &__buttons {
//         float: right;
//         position: relative;
//         top: 3px;
//         right: 5px;
//         font-size: 1em;

//         a {

//         }
//     }
// }
