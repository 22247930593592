@keyframes selector-pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}

.local-map {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    overflow: hidden;
    outline: none;

    background: rgba(0, 0, 0, 0.15);
    border: 2px rgba(0, 0, 0, 0.25) solid;
    box-shadow: 0 0 20px -10px black;

    &__layers {
        position: fixed;
        top: 50%;
        left: 50%;
        transform-origin: center;
        transition: opacity 250ms;

        // background: rgba(0, 0, 0, 0.15);
        // border: 2px rgba(0, 0, 0, 0.25) solid;
        // box-shadow: 0 0 20px -10px black;
    }

    &__layer {
        position: absolute;
        top: 0;
        left: 0;
    }

    &__selector {
        position: absolute;
        top: 0;
        left: 0;
        background: none;
        z-index: 9999;
        animation: selector-pulse 2s infinite ease-in-out;
        pointer-events: none;
        transition: opacity 0.25s;
        opacity: 0;

        &:before {
            content: "";
            display: block;
            position: absolute;
            top: -50px;
            left: -50px;
            height: 100px;
            width: 100px;
            border-radius: 100px;
            border: 1px $interactive-highlight solid;
            box-shadow: 0 0 6px 2px $interactive-highlight inset;
        }
    }

    &__starglow-layer {
        position: fixed;
        left: 50%;
        top:50%;
    }
}

// .system-map-info {
//     position: absolute;
//     left: 0px;
//     top: 0px;
//     min-height: 300px;
//     // border-radius: 5px;
//     // box-shadow: 0 0 20px -10px #000;
    
//     z-index: 9999;
//     // background: $view-bg;
//     padding: 20px 40px;
//     transition: opacity 0.15s;
//     box-shadow: none;
//     border: none;
//     background: rgba(0,0,0,0.2);
//     border-right: rgba(0, 0, 0, 0.075) 1px solid;
//     height: 100%;

//     $divider-color: lighten(
//         $color: $panel-bg,
//         $amount: 10,
//     );

//     &--open {
//         opacity: inherit;
//         pointer-events: all;
//     }

//     &__section {
//         margin-bottom: 30px;
//     }

//     &__header {
//         font-size: 2rem;
//         font-size: 100;
//         font-weight: lighter;

//         svg {
//             height: 2rem;
//             position: relative;
//             fill: $ui-base;
//             top: -2px;
//         }
//     }

//     h1 {
//         font-size: 1.5em;
//         text-align: left;
//         margin: 0 auto;
//         margin-bottom: -10px;
//         font-weight: normal;
//         font-weight: 100;
//     }

//     p {
//         text-align: left;
//     }

//     hr {
//         margin: 20px auto;
//         width: 100%;
//         height: 1px;
//         background: linear-gradient(
//             90deg,
//             transparentize($divider-color, 1) 5%,
//             $divider-color 50%,
//             transparentize($divider-color, 1) 95%
//         );
//         border: none;
//     }

//     &__table {
//         margin: 10px 0 !important;

//         td {
//             text-align: right;
//         }
//     }

//     &__owner {
//         // border-top: $ui-base 1px solid;
//         // border-bottom: $ui-base 1px solid;
//         text-align: center;
//         font-size: 0.8rem;
//         margin: -10px 0 0 -20px;
//         width: calc(100% + 40px);
//         background: rgba(0, 0, 0, 0.15);
//         border-bottom: lighten($color: $panel-bg, $amount: 10) 1px solid;
//         padding: 3px;
//     }

//     &__home {
//         font-size: 0.8rem;
//         text-align: center;
//         padding-top: 5px;
//         margin: 10px auto 20px auto;
//         background: linear-gradient(
//             90deg,
//             transparentize($divider-color, 1) 5%,
//             $divider-color 50%,
//             transparentize($divider-color, 1) 95%
//         );

//         &-name {
//             font-size: 1.2rem;
//         }
//     }
// }
