
@keyframes loader-close {
    0% {
        opacity: 1
    }

    100% {
        opacity: 0
    }
}

.loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #0c0c0c;
    
    z-index: 999999;
    font-family: "Alegreya Sans SC", sans-serif;
    font-weight: 100;
    text-shadow: 0 0 10px #fff;
    overflow: hidden;
    color: #fff;

    &:after {
        display: block;
        content: "";
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: radial-gradient(circle at 10% -20%, rgba(20, 169, 255, 0.1) 0%, rgba(255, 160, 20, 0) 100%);
    }

    &--closing {
        animation: loader-close 0.25s linear 1 forwards;
    }

    &__title {
        font-size: 5em;
        text-align: center;
        margin-top: 25vh;
        margin-left: auto;
        margin-right: auto;

    }

    &__subtitle {
        font-size: 2em;
        text-align: center;
        font-family: "Alegreya Sans SC", sans-serif;
        // font-style: italic;
    }

    &__message{
        font-family: "Alegreya Sans", sans-serif;
        text-align: center;
        font-size: 1.5em;
        text-shadow: 0 0 10px #fff;
    }

    &__status {
        text-align: center;
        position: absolute;
        bottom: 5vh;
        width: 100%;    
    }

    &__progress-bar {
        background: rgba(255,255,255,0.5);
        height: 2px;
        width: 10vw;
        margin: 20px auto;
        overflow: hidden;
        border-radius: 5px;
        position: relative;
        box-shadow: 0 0 5px #fff;
    }

    &__progress-fill {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 0;
        background: $ui-base;
        
    }

}