
@media only screen and (max-width: 600px) {
    .main-panel {
        background: rgba(0,0,0,0.75);
    }

    .music-player__seek-wrapper { display: none; }

    .performance-monitor { display: none; }

    .map-info {
        width: calc(100%);
        top: 47px;
        
        h1 {
            margin-top: -30px;
        }
    }

}