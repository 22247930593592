@keyframes selector-pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}

.system-map {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    overflow: hidden;
    outline: none;

    background: rgba(0, 0, 0, 0.15);
    border: 2px rgba(0, 0, 0, 0.25) solid;
    box-shadow: 0 0 20px -10px black;

    &__layers {
        position: fixed;
        top: 50%;
        left: 50%;
        transform-origin: center;
        transition: opacity 250ms;

        // background: rgba(0, 0, 0, 0.15);
        // border: 2px rgba(0, 0, 0, 0.25) solid;
        // box-shadow: 0 0 20px -10px black;
    }

    &__layer {
        position: absolute;
        top: 0;
        left: 0;
    }

    &__selector {
        position: absolute;
        top: 0;
        left: 0;
        background: none;
        z-index: 9999;
        animation: selector-pulse 2s infinite ease-in-out;
        pointer-events: none;
        transition: opacity 0.25s;
        opacity: 0;

        &:before {
            content: "";
            display: block;
            position: absolute;
            top: -50px;
            left: -50px;
            height: 100px;
            width: 100px;
            border-radius: 100px;
            border: 1px $interactive-highlight solid;
            box-shadow: 0 0 6px 2px $interactive-highlight inset;
        }
    }
}

.system-map-info {
   
}
