.performance-monitor {
 
    position: fixed;
    bottom: 0px;
    width: 100%;
    padding: 5px 10px;
    font-size: 0.8rem;
    
    box-shadow: 0 0 10px #000;
    border-top: #666 1px solid;
    background: rgba(0,0,0,0.8);
    
    z-index: 9999999;

    &__section {
        display: inline-block;
        padding: 0 20px;
        margin-right: 20px;
        
    }

    p {
        margin: 0;
        font-weight: bold;
        padding: 0 5px;
        font-size: 1.25em;
        text-align: left;
    }

    &__stat {
        display: inline-block;
        padding: 0 5px;
        text-align: left;

        span:first-child {
            font-weight: bold;
            padding-right: 3px;
        }

        span:last-child {
            display: inline-block;
            width: 50px;
        }
    }
}