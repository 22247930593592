
.music-player {
    display: block;
    width: auto;
    min-width: 10vw;
    font-size: 0.8em;
    margin: 0;
    margin-right: auto;
    position: fixed;
    right: 0px;
    bottom: 0px;
    padding: 5px 10px;
    z-index: 999999;
    
    // border-radius: 5px 0 0 0;
    
    &__random {
        opacity: 0.3;
    }

    &__controls {
        display:inline-block;
        
        margin-left: 10px;

        svg {
            height: 10px;
            fill: #fff;
            margin-top: -1px;
        }

        > span {
            padding-right: 5px;
           
        }
        
        .music-player__pause { 
            display:none;
        }

        .music-player__next { 
            // display: none; 
            svg {
                height: 16px;
            }
        }

        .music-player__random {
            display:none;
        }

    }
    
   

    &__track {
        display: inline-block;
    }

    &__seek {
        display:inline-block;
        height: 2px;
        width: 100px;
        background: rgba(255,255,255,0.25);
        position: relative;
        top: -2px;
        left: 5px;
        transition: all 150ms;
        overflow: hidden;
        cursor:pointer;
        
      
    }
    
    &__seek-wrapper:hover {
        .music-player__seek,
        .music-player__volume-bar {
            height: 10px;
            top: 1px;
        }

        .music-player__progress {
            // background: $interactive-highlight;
        }
    }
    
    &__progress {
        width: 100%;
        height: 100%;
        transform: translateX(-100px);
        background: #fff;
        pointer-events: none;
        transition: background 0.25s;
    }
    
    &__title {
        display: inline-block;
        font-style: italic;
    }
    
    &__attribution {
        display: inline-block;
        
        a {
            color: inherit;
            
        }
    }
    
    &--playing {
        .music-player__controls {
            .music-player__play {
                display:none;
            }
            
            .music-player__pause {
                display:inline-block;
            }
        }
    }
    
    &--random {
        .music-player__random {
            opacity: 1;
        }
    }

}