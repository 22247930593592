@keyframes selector-pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}

.map {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    overflow: hidden;
    outline: none;
    background: #050505;// rgba(0, 0, 0, 0.15);

    &__star-layer {
        
    }

    &__layers {
        position: fixed;
        top: 50%;
        left: 50%;
        transform-origin: center;
        border-radius: 5000px;
        overflow: hidden;

        // background: rgba(0, 0, 0, 0.15);
        // border: 2px rgba(0, 0, 0, 0.25) solid;
        // box-shadow: 0 0 20px -10px black;
    }

    &__layer {
        position: absolute;
        top: 0;
        left: 0;
        // filter: saturate(2) contrast(1.5) brightness(0.8);
    }

    &__selector {
        position: absolute;
        top: 0;
        left: 0;
        background: none;
        z-index: 9999;
        animation: selector-pulse 2s infinite ease-in-out;
        pointer-events: none;
        transition: opacity 0.25s;
        opacity: 0;

        &:before {
            content: "";
            display: block;
            position: absolute;
            top: -50px;
            left: -50px;
            height: 100px;
            width: 100px;
            border-radius: 100px;

            border: 5px #fff solid;
            box-shadow: 0 0 20px -5px #fff, 0 0 20px -5px #fff inset;

            // border: 1px $interactive-highlight solid;
            // box-shadow: 0 0 6px 2px $interactive-highlight inset;
        }
    }
}

.map-info {
    // position: absolute;
    // left: 0px;
    // top: 0px;
    // z-index: 9999;
    // width: 335px;
    // // background: $view-bg;
    // padding: 60px 40px 20px 40px;
    // opacity: 0;
    // transition: opacity 0.15s;
    // pointer-events: none;

    // box-shadow: none;
    // border: none;
    // background: rgba(0,0,0,0.75);
    // border-right: rgba(0, 0, 0, 0.075) 1px solid;
    // height: 100%;

    // $divider-color: #fff;

    // &--open {
    //     opacity: inherit;
    //     pointer-events: all;
    // }

    // h1 {
    //     font-size: 2em;
    //     text-align: center;
    //     margin: 0 auto;
    //     margin-bottom: 20px;
    //     font-weight: normal;
    //     font-weight: 100;
    // }

    // p {
    //     text-align: center;
    // }

    // hr {
    //     margin: 20px auto;
    //     width: 100%;
    //     height: 1px;
    //     background: linear-gradient(90deg, transparentize($divider-color, 1) 5%, $divider-color 50%, transparentize($divider-color, 1) 95%);
    //     border: none;
    // }

    // &__table {
    //     margin: 10px auto;
    //     width: 100%;
    //     td {
    //         text-align: right;
    //     }
    // }


    &__home,
    &__owner {

        font-size: 0.8rem;
        text-align: center;
        padding-top: 5px;
        margin: -10px auto 20px auto;
        // background: linear-gradient(90deg, transparentize($divider-color, 1) 5%, $divider-color 50%, transparentize($divider-color, 1) 95%);
        // color: #0c0c0c;
        
        &-name {
            font-size: 1.2rem;
        }
    }

    &__civ {
        border: 2px #fff solid;
    }


}
