.settings-view {
    &__option {
        width: 50vw;
        margin: 20px auto;
        font-family: "Alegreya Sans", sans-serif;
        font-weight: 100;
        margin-bottom: 20px;
        padding-bottom: 10px;
        border-bottom: rgba(255,255,255,0.1) 1px solid;

        &:last-child {
            border-bottom: none;
        }
    }

    &__option-title {
        font-size: 2em;
        font-family: "Alegreya Sans SC", sans-serif;
    }

    &__option-desc {
        font-size: 1.25em;
        margin: 0 0 7px 0;
    }

    &__option-input {
        font-size: 1.5em;

        select {
            margin-bottom: 20px;
        }

        select,
        label,
        input[type='text'] {
            display:block;
            border-radius: 3px;
            width: 300px;
            padding: 3px;
        }

        input[type='text'] {
            width: 100px;
        }

    }
}