.ui__panel {
    z-index: 999;
    // background: linear-gradient(90deg, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0) 100%), $panel-bg;
    // box-shadow: 0 0 20px -10px #000;
    color: $ui-base;
    background: rgba(12,12,12,0.75);
    border: rgba(16,16,16,0.75) 1px solid;


    &--floating {
        // border: lighten($color: $panel-bg, $amount: 10) 1px solid;
    }


}

.ui__button {
    cursor:pointer; 
    transition: all 0.25s;
    text-decoration: none;
    color: $ui-base !important;

    svg {
        transition: all 0.25s;
    }

}

.info-panel {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 350px;
    height: 100%;
    padding: 60px 20px 20px 20px;
    z-index: 9999;
    opacity: 0;
    transition: opacity 0.15s;
    box-shadow: none;
    border: none;
    background: rgba(0,0,0,0.75);
    border-right: rgba(0, 0, 0, 0.075) 1px solid;
    pointer-events: none;

    $divider-color: #fff;

    &--open {
        opacity: 1;
        pointer-events: all;
    }

    h1, h2, h3, h4 {
        text-align: center;
        font-weight: 100;
    }

    h1 {
        font-size: 2rem;
        margin: 0 auto;
        margin-bottom: 20px;
    }

    h2 {
        font-size: 1.5rem;
    }

    h3 {
        font-size: 1rem;
    }

    p {
        text-align: center;
    }

    hr {
        margin: 20px auto;
        width: 100%;
        height: 1px;
        background: linear-gradient(90deg, transparentize($divider-color, 1) 5%, $divider-color 50%, transparentize($divider-color, 1) 95%);
        border: none;
    }

    &__table {
        margin: 10px auto;
        width: 100%;
        td {
            text-align: right;
        }
    }

    blockquote {
        font-style: italic;
    }


    &__civ {
        // border: 1px #fff solid;
        margin: 20px 0;
        padding: 5px 10px;
        border-radius: 3px;
        transition: all 250ms ease-in-out;
        cursor:pointer;

        &:hover {
            filter: brightness(1.5)
        }

    }


}