@import url("../../node_modules/bootstrap/dist/css/bootstrap-reboot.min.css"); 
@import url("../../node_modules/bootstrap/dist/css/bootstrap-grid.min.css"); 
// @import url('https://fonts.googleapis.com/css2?family=Alegreya+Sans+SC:ital,wght@0,400;0,700;1,400&family=Alegreya+Sans:ital,wght@0,400;0,700;1,400&display=swap');

@import "colors";

@import "ui";
@import "general";
@import "components";
@import "views";
@import "mobile";

* {
    user-select: none;
    outline: none;
}


