.system-map-view {
    
    background: $app-surface;

    &__content {
        min-width: 300px;
        max-width: 50vw;
        margin: auto;
        text-align: justify;
    }
         
    
    
}